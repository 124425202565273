import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';

import { readContract, waitForTransactionReceipt, writeContract } from '@wagmi/core';
import axios, { AxiosRequestConfig } from 'axios';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { bettingContractConfig, nftContractConfig, tokenContractConfig } from './config';
import { useLoader } from './context/LoaderContext';
import { useWallet } from './context/WalletContext';
import Layout from './layouts/main';
import { marketEnumMapping, reverseMarketEnumMapping, TokenEnumMapping } from './shared';
import { Bet } from './shared/Bet';
import { createHmac } from './shared/createHmac';
import { formatDateWithAmPm } from './shared/formatDateWithAmPm';
import { handleMetaMaskError } from './utilities/handleMetaMaskError';
import useNotification from './utilities/notificationUtils';
import { wagmiConfig } from './wagmiConfig';
import { getToken } from './shared/getToken';

const NFT_COLLECTION_LINK = "https://sudoswap.xyz/#/browse/sanko/buy/0xc447597efc493e36ccca16707559fb7238d0367c";

export default function Game() {
    const { account, connected } = useWallet();

    const [openMarketDialog, setOpenMarketDialog] = useState<boolean>(false);
    const [openHigherLowerDialog, setOpenHigherLowerDialog] = useState<boolean>(false);
    const [openTokenDialog, setOpenTokenDialog] = useState<boolean>(false);
    const [openAmountDialog, setOpenAmountDialog] = useState<boolean>(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);

    const [selectedMarket, setSelectedMarket] = useState<string>('');
    const [selectedToken, setSelectedToken] = useState<string>('');
    const [higher, setHigher] = useState<boolean>(false);
    const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
    const [amountOptions, setAmountOptions] = useState<number[]>([]);

    const [openWinDialog, setOpenWinDialog] = useState<boolean>(false);
    const [winMessage, setWinMessage] = useState<string>('');

    const [openLooseDialog, setOpenLooseDialog] = useState<boolean>(false);

    const { showLoader, hideLoader, setLoaderMessage } = useLoader();
    const { showError, showSuccess } = useNotification();

    const markets: string[] = ['ETH', 'ARB', 'APE', 'SOL'];

    const amounts: number[] = [1, 5, 10];

    const [currentBet, setCurrentBet] = useState<Bet | null>(null);
    const [timer, setTimer] = useState<number | null>(null);

    const handleMarketSelect = (market: string) => {
        setSelectedMarket(market);
        setOpenMarketDialog(false);
        setOpenHigherLowerDialog(true); // Open higher/lower dialog after selecting market
    };

    const handleHigherLowerSelect = (choice: string) => {
        console.log(`Selected market: ${selectedMarket}, choice: ${choice}`);
        setHigher(choice === 'Higher');
        setOpenHigherLowerDialog(false);
        // setOpenTokenDialog(true); // Open token selection after higher/lower
        setSelectedToken('APE');
        setAmountOptions(amounts);
        setOpenTokenDialog(false);
        setOpenAmountDialog(true);
    };

    // const handleTokenSelect = (token: string) => {
    //     setSelectedToken(token);
    //     setAmountOptions(token === 'SP' ? spAmounts : (token === 'SILVER' ? silverAmounts : goldAmounts));
    //     setOpenTokenDialog(false);
    //     setOpenAmountDialog(true);
    // };

    const handleAmountSelect = (amount: number) => {
        console.log(`Selected amount: ${amount}`);
        setSelectedAmount(amount);
        setOpenAmountDialog(false);
        setOpenConfirmationDialog(true);
    };

    const loadCurrentBet = async () => {
        const bet: any = await readContract(wagmiConfig, {
            address: bettingContractConfig.contractAddress,
            abi: bettingContractConfig.abi,
            functionName: 'getCurrentPlay',
            args: [account],
        });

        const endTime = Number(bet.endTime);

        return {
            player: bet.player,
            market: Number(bet.market),
            amount: ethers.formatUnits(bet.amount, getToken(Number(bet.tokenType)).decimals),
            isHigher: bet.isHigher,
            startPrice: ethers.formatUnits(bet.startPrice),
            endPrice: ethers.formatUnits(bet.endPrice),
            endTime: endTime,
            claimed: bet.claimed,
            won: bet.won,
            tokenType: Number(bet.tokenType),
        } as Bet;
    }

    const fetchCurrentBet = async () => {
        try {
            const bet = await loadCurrentBet();
            const endTime = bet.endTime;
            if (Number(bet.endPrice) === 0) {
                const startPrice = await fetchPrice(reverseMarketEnumMapping[bet?.market ?? 0], endTime - 4 * 60 - 20);
                bet.startPrice = startPrice?.toString();
            }
            setCurrentBet(bet);
            if (Number(bet.endPrice) == 0) {
                const currentTime = Math.floor(Date.now() / 1000);
                setTimer(endTime - currentTime);
            }
        } catch (error: any) {
            console.log("fetchCurrentBet error", error);

            const message = handleMetaMaskError(error);
            if (!message.includes("No plays found for this player")) {
                showError(handleMetaMaskError(error));
            }
        }
    };

    const fetchPrice = async (symbol: string, timestamp: number | null = null) => {
        console.log(symbol);

        const data = {
            symbol,
            timestamp
        };
        const hmac = createHmac(JSON.stringify(data));

        const config: AxiosRequestConfig = {
            headers: {
                'X-HMAC': `${hmac}`,
                'Content-Type': 'application/json'
            }
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/singleprice`, data, config);
            return Number(response?.data?.price || "0");
        } catch (error) {
            console.error('Error fetching data:', error);
            return 0;
        }
    };

    const checkSimCard = async () => {
        try {
            const nftBalance = await readContract(wagmiConfig, {
                address: nftContractConfig.contractAddress,
                abi: nftContractConfig.abi,
                functionName: 'balanceOf',
                args: [account],
            });

            if (Number(nftBalance) <= 0) {
                window.location.href = NFT_COLLECTION_LINK;
                throw new Error("You do not have a sim card!");
            }
        } catch (error) {
            throw error;
        }
    };

    // const approveToken = async (amount: bigint) => {
    //     try {
    //         const tx = await writeContract(wagmiConfig, {
    //             address: getToken(TokenEnumMapping[selectedToken]).address,
    //             abi: tokenContractConfig.abi,
    //             functionName: 'approve',
    //             args: [bettingContractConfig.contractAddress, amount],
    //         });
    //         await waitForTransactionReceipt(wagmiConfig, {
    //             hash: tx
    //         });
    //     } catch (error) {
    //         throw error;
    //     }
    // };

    const handleConfirm = async () => {
        setOpenConfirmationDialog(false);

        try {
            showLoader();
            // TODO: Currently removed
            // setLoaderMessage("Checking for Smoove Mobile Sim Card");
            // await checkSimCard();

            const betAmount = ethers.parseUnits(selectedAmount?.toString() ?? "0", getToken(TokenEnumMapping[selectedToken]).decimals);
            setLoaderMessage("Approve the transaction to continue");

            // await approveToken(betAmount);

            // setLoaderMessage(`Transaction Approved. Initiating Position`);

            const tx = await writeContract(wagmiConfig, {
                address: bettingContractConfig.contractAddress,
                abi: bettingContractConfig.abi,
                functionName: 'placePlay',
                args: [
                    marketEnumMapping[selectedMarket],
                    higher
                ],
                value: betAmount
            });

            await waitForTransactionReceipt(wagmiConfig, {
                hash: tx
            });
            await fetchCurrentBet();
            showSuccess(`🤞 Cross your fingers and stay tuned for the results!`);
        } catch (error: any) {
            showError(handleMetaMaskError(error));
        } finally {
            setLoaderMessage("Loading...");
            hideLoader();
        }
    };


    useEffect(() => {
        if (connected) {
            fetchCurrentBet();
        }
    }, [connected]);

    useEffect(() => {
        console.log("Timer user effect called", timer);

        let timerInterval: NodeJS.Timeout | null = null;

        if (timer != null) {
            if (timer > 0) {
                timerInterval = setInterval(() => {
                    setTimer(prev => (prev ?? 1) - 1);
                }, 1000);
            } else {
                handleVerify();
                if (timerInterval) {
                    clearInterval(timerInterval);
                }
                setTimer(null);
            }
        }

        return () => {
            if (timerInterval) clearInterval(timerInterval);
        };
    }, [timer]);


    const resolvePlayerBet = async (player: string) => {
        showLoader();
        const data = {
            player
        };

        const hmac = createHmac(JSON.stringify(data));
        const config: AxiosRequestConfig = {
            headers: {
                'X-HMAC': `${hmac}`,
                'Content-Type': 'application/json'
            }
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/ape/verify`, data, config);
            console.log(response);

            if (response?.status === 200) {
                while (true) {
                    await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve("Waited for 5 seconds!");
                        }, 5 * 1000);
                    });
                    const bet = await loadCurrentBet();
                    console.log("resolvePlayerBet");
                    if (Number(bet.endPrice) != 0) {
                        console.log("I am here");
                        if (bet.won) {
                            setWinMessage(`Your prediction was correct! You won! Your prize has been sent to your wallet!`);
                            setOpenWinDialog(true);
                        } else {
                            setOpenLooseDialog(true);
                        }
                        break;
                    }
                }
            }
            await fetchCurrentBet();
        } catch (error) {
            showError(handleMetaMaskError(error));
        } finally {
            hideLoader();
        }
    };

    const handleVerify = async () => {
        if (account) {
            resolvePlayerBet(account || "");
        }
    };

    const formatTime = (totalSeconds: number) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${String(minutes).padStart(1, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const handleClaim = async () => {
        try {
            showLoader();
            const tx = await writeContract(wagmiConfig, {
                address: bettingContractConfig.contractAddress,
                abi: bettingContractConfig.abi,
                functionName: 'claimReward'
            });

            await waitForTransactionReceipt(wagmiConfig, {
                hash: tx
            });
            await fetchCurrentBet();
            showSuccess(`Your prize has been sent to your wallet!`);
        } catch (error: any) {
            showError(handleMetaMaskError(error));
        } finally {
            setLoaderMessage("Loading...");
            hideLoader();
        }
    };

    const BetOutcomeMessage = () => {
        if (currentBet === null) return null;

        return (
            <Box>
                {currentBet.won ? (
                    <>
                        <Box sx={{ 'border': '6px dashed', marginBottom: '10px' }}>
                            <Typography variant="body1" >🎉 Your prediction was correct! You won! Claim your reward! 🎉</Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    marginTop: 3,
                                    marginBottom: 2
                                }}
                                onClick={handleClaim}
                            >
                                Claim
                            </Button>
                        </Box>
                    </>
                ) : (
                    <Typography variant="body1" sx={{ 'border': '6px dashed' }}>😢 Your prediction was wrong!. Better luck next time!</Typography>
                )}
                <Box mt={1} mb={2} sx={{ 'border': '6px dashed' }}>
                    <Typography variant="body1">
                        <strong>Market:</strong> {reverseMarketEnumMapping[currentBet?.market ?? 0]}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Amount:</strong> {currentBet.amount} {getToken(currentBet.tokenType).symbol}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Prediction:</strong> {currentBet.isHigher ? 'Higher' : 'Lower'}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Start Price:</strong> {currentBet.startPrice}
                    </Typography>
                    <Typography variant="body1">
                        <strong>End Price:</strong> {currentBet.endPrice}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Start Time:</strong> {formatDateWithAmPm(currentBet.endTime - 4 * 60 - 20)}
                    </Typography>
                    <Typography variant="body1">
                        <strong>End Time:</strong> {formatDateWithAmPm(currentBet.endTime)}
                    </Typography>

                </Box>
            </Box>
        );
    };

    return (
        <Layout>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    textAlign: 'center',
                    backgroundColor: 'black',
                    padding: 2,
                }}
            >
                {currentBet == null || currentBet.claimed ? (
                    <>
                        <Typography variant="h4" sx={{ marginBottom: 2, border: '2px dashed', padding: '5px' }}>
                            Predict the price trend correctly after 4 Minutes and 20 Seconds and 1.6X your  deposit!
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{
                                marginTop: 3,
                            }}
                            onClick={() => setOpenMarketDialog(true)}
                        >
                            Play
                        </Button>
                    </>
                ) : (
                    <>
                        {
                            Number(currentBet.endPrice) === 0
                                ?
                                <Box sx={{ border: '6px dashed', padding: '5px' }}>
                                    <Typography variant="h2">Current Deposit Details</Typography>
                                    <Typography>Market: {reverseMarketEnumMapping[currentBet?.market ?? 0]}</Typography>
                                    <Typography variant='body2'>Start price: ${currentBet?.startPrice}</Typography>
                                    <Typography>Amount: {currentBet?.amount} {getToken(currentBet.tokenType).symbol}</Typography>
                                    <Typography>
                                        <strong>Prediction:</strong> {currentBet.isHigher ? 'Higher' : 'Lower'}
                                    </Typography>
                                    <Typography>
                                        Status: {currentBet?.claimed ? 'Claimed' : 'Active'}
                                    </Typography>
                                    {timer != null && <Typography variant='body2'>Time Remaining: {timer > 0 ? formatTime(timer) : "0:00"} seconds</Typography>}
                                    {timer == null || timer <= 0 && (
                                        <Button
                                            variant="contained"
                                            sx={{ marginTop: 2 }}
                                            onClick={handleVerify}
                                        >
                                            Verify
                                        </Button>
                                    )}
                                </Box>
                                :
                                <BetOutcomeMessage />
                        }
                    </>
                )}

                {/* Market Selection Dialog */}
                <Dialog open={openMarketDialog} onClose={() => setOpenMarketDialog(false)} fullWidth maxWidth="sm">
                    <DialogTitle>Select a Market</DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Grid container spacing={2}>
                                {markets.map((market) => (
                                    <Grid item xs={12} sm={4} key={market}>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                margin: 1,
                                                width: '100%',
                                            }}
                                            onClick={() => handleMarketSelect(market)}
                                        >
                                            {market}
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenMarketDialog(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog>

                {/* Higher or Lower Selection Dialog */}
                <Dialog open={openHigherLowerDialog} onClose={() => setOpenHigherLowerDialog(false)} fullWidth maxWidth="sm">
                    <DialogTitle>Select Higher or Lower</DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                sx={{
                                    margin: 1,
                                    width: '45%',
                                }}
                                onClick={() => handleHigherLowerSelect('Higher')}
                            >
                                Higher
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    margin: 1,
                                    width: '45%',
                                }}
                                onClick={() => handleHigherLowerSelect('Lower')}
                            >
                                Lower
                            </Button>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenHigherLowerDialog(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog>

                {/* Token Selection Dialog */}
                {/* <Dialog open={openTokenDialog} onClose={() => setOpenTokenDialog(false)} fullWidth maxWidth="sm">
                    <DialogTitle>Select a Token</DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                sx={{
                                    margin: 1,
                                    width: '45%',
                                }}
                                onClick={() => handleTokenSelect('SP')}
                            >
                                $SP
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    margin: 1,
                                    width: '45%',
                                }}
                                onClick={() => handleTokenSelect('SILVER')}
                            >
                                Sanko Silver
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    margin: 1,
                                    width: '45%',
                                }}
                                onClick={() => handleTokenSelect('GOLD')}
                            >
                                Sanko Gold
                            </Button>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenTokenDialog(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog> */}

                {/* Amount Selection Dialog */}
                <Dialog open={openAmountDialog} onClose={() => setOpenAmountDialog(false)} fullWidth maxWidth="sm">
                    <DialogTitle>Select an Amount</DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {amountOptions.map((amount) => (
                                <Button
                                    key={amount}
                                    variant="contained"
                                    sx={{
                                        margin: 1,
                                        width: '100%',
                                    }}
                                    onClick={() => handleAmountSelect(amount)}
                                >
                                    {amount}
                                </Button>
                            ))}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenAmountDialog(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog>

                {/* Confirmation Dialog */}
                <Dialog open={openConfirmationDialog} onClose={() => setOpenConfirmationDialog(false)} fullWidth maxWidth="sm">
                    <DialogTitle>Confirm Deposit</DialogTitle>
                    <DialogContent>
                        <Typography>Market: {selectedMarket}</Typography>
                        <Typography>Amount: {selectedAmount}</Typography>
                        <Typography>Token: {selectedToken}</Typography>
                        <Typography>Direction: {higher ? 'Higher' : 'Lower'}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirm}>Confirm</Button>
                        <Button onClick={() => setOpenConfirmationDialog(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openWinDialog} onClose={() => setOpenWinDialog(false)}>
                    <DialogTitle sx={{ textAlign: 'center', border: '2px dashed', }}>Congratulations!</DialogTitle>
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <video
                            src={'/winning_video.MP4'}
                            controls
                            style={{ height: '300px' }}
                        />
                        <Typography sx={{ textAlign: 'center', marginTop: 2, border: '2px dashed', }}>{winMessage}</Typography>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                        <Button onClick={() => setOpenWinDialog(false)}>Close</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openLooseDialog} onClose={() => setOpenLooseDialog(false)}>
                    <DialogTitle sx={{ textAlign: 'center', border: '2px dashed', }}>Your prediction was wrong!. Better luck next time!</DialogTitle>
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img
                            src="/loosing_image.jpg"
                            alt="Loose Image"
                            style={{
                                width: '100%',
                                maxWidth: '250px',
                                height: 'auto',
                                border: '2px dashed',
                                borderRadius: '8px'
                            }}
                        />
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center', border: '2px dashed', }}>
                        <Button onClick={() => setOpenLooseDialog(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Box >
        </Layout >
    );
}
